import { Transition } from "@headlessui/react"
import { useViewer } from "common/hooks/data/useViewer"
import { PropsWithChildren } from "react"

/**
 * Component that will render the transition for the tabbed navigation, fading in the navigation when
 * the viewer is loaded.
 */
export const TabbedNavigationTransition = ({ children }: PropsWithChildren) => {
  const { loaded: loadedViewer } = useViewer()

  return (
    <>
      <Transition show={!loadedViewer}>
        <div className="h-8 transition data-[leave]:h-0 lg:h-10"></div>
      </Transition>
      <Transition show={loadedViewer}>
        <div className="h-8 transition data-[closed]:opacity-0 lg:h-10">{children}</div>
      </Transition>
    </>
  )
}
