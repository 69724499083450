import { useGraphqlQuery } from "./useGraphqlQuery"
import { Query } from "common/types/graphql"
import { gql } from "common/lib/gql"
import { useCallback } from "react"

export const EMAILS_QUERY = gql`
  query {
    viewer {
      user {
        emails {
          email
          primary
          newsletterSubscriptions {
            newsletterListId
            subscribed
            newsletterList {
              id
              frequency
              name
              pitch
              region {
                code
              }
            }
          }
        }
      }
    }
  }
`

/**
 * Data hook to return the current viewer newsletter subscriptions data.
 *
 * Newsletter subscriptions are only related to their primary email address.
 */
export const useViewerSubscriptions = () => {
  const { data } = useGraphqlQuery<Query>(EMAILS_QUERY)

  const getPrimaryEmail = useCallback(
    () => data?.viewer?.user?.emails?.find((email) => email.primary),
    [data?.viewer?.user?.emails],
  )

  const getPrimaryEmailAddress = useCallback(
    () => getPrimaryEmail()?.email || "",
    [getPrimaryEmail],
  )

  const getNewsletterSubscriptions = useCallback(
    () => getPrimaryEmail()?.newsletterSubscriptions?.filter(({ subscribed }) => subscribed) || [],
    [getPrimaryEmail],
  )

  return {
    getNewsletterSubscriptions,
    getPrimaryEmailAddress,
  }
}
