import { ImgixImage } from "common/components/ImgixImage"
import { closestCrop } from "modules/editor/utils/closestCrop"
import { ImageAspectRatio } from "modules/editor/types"
import { imageSizes } from "common/lib/imageSizes"
import { Image, Maybe } from "common/types/graphql"
import { ReactNode } from "react"

const IMAGE_DIMENSIONS = { width: 316, height: 180 }
const FALLBACK_PLACEHOLDER_COLOR = "#000000"

export interface CardProps {
  image?: Maybe<Partial<Image>>
  imageAlt?: string
  children?: ReactNode
}

export const Card = ({ children, image, imageAlt }: CardProps) => {
  return (
    <div className="flex size-full flex-col overflow-hidden rounded-lg bg-white shadow">
      {image?.src && (
        <div className="relative aspect-video">
          <ImgixImage
            src={image.src}
            alt={imageAlt || image.alt}
            dimensions={IMAGE_DIMENSIONS}
            placeholderColor={image.placeholderColor || FALLBACK_PLACEHOLDER_COLOR}
            crop={closestCrop(ImageAspectRatio.Widescreen, image.crops || [])}
            sizes={imageSizes({ lg: 0.25, sm: 1 })}
          />
        </div>
      )}
      <section className="flex h-full flex-col items-start gap-2 p-2.5 lg:p-4">{children}</section>
    </div>
  )
}
