import { NewspaperIcon, PencilIcon } from "@heroicons/react/24/solid"
import clsx from "classnames"
import { FC, SVGProps } from "react"

const tabs = [
  { name: "Subscribe", href: "#", icon: NewspaperIcon, current: false },
  { name: "Manage my newsletters", href: "#", icon: PencilIcon, current: true },
]

export interface TabProps {
  name: string
  href: string
  icon: FC<SVGProps<SVGSVGElement>>
  current: boolean
}

interface TabbedMenuProps {
  tabs: TabProps[]
}

/**
 * TabbedMenu component, which accepts an array of "Tabs" composed of a name, href, icon, and current status.
 */
export const TabbedMenu = ({ tabs }: TabbedMenuProps) => {
  if (tabs.length === 0) {
    return null
  }

  return (
    <nav aria-label="Tabs" className="flex justify-center">
      {tabs.map((tab) => (
        <a
          key={tab.name}
          href={tab.href}
          aria-current={tab.current ? "page" : undefined}
          className={clsx(
            "inline-flex items-center pb-1.5 pr-4 text-sm font-bold last:pr-0 lg:pb-3",
            {
              "border-b-4 border-red-500 text-red-600": tab.current,
              "border-b border-gray-500 text-black": !tab.current,
            },
          )}
        >
          <tab.icon
            aria-hidden="true"
            className={clsx("mr-2.5 size-3.5 lg:size-6", {
              "text-red-500": tab.current,
              "text-gray-400": tab.current,
            })}
          />
          <span className="font-sans-heading text-2xs lg:text-sm">{tab.name}</span>
        </a>
      ))}
    </nav>
  )
}
