import { useTranslations } from "modules/i18n/hooks/useTranslations"

export const PrivacyPolicy = () => {
  const t = useTranslations("newsletters")

  /*
    Privacy policy copy is coming from our I18n files, which needs to support HTML tags so translators can add
    links to the privacy policy page. We are consciously using `dangerouslySetInnerHTML` here because we trust
    the source translations (coming from Phrase).
  */
  return (
    <div
      className="mx-auto my-16 w-10/12 text-center text-2xs lg:w-4/12"
      dangerouslySetInnerHTML={{ __html: t("privacy_policy_html") }}
    />
  )
}
