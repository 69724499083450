import { StickyDialog } from "common/components/StickyDialog"
import { useCallback, useEffect, useRef, useState } from "react"
import { useSubscribedNewslettersContext } from "modules/newsletters/contexts"
import {
  ErrorResponseFormat,
  useToggleSubscription,
} from "modules/newsletters/hooks/useToggleSubscription"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { useNotifications } from "common/components/Notification"
import ReCAPTCHA from "react-google-recaptcha"
import { usePageProps } from "common/hooks/data/usePageProps"
import { NewslettersPageProps } from "pages/newsletters/index.page"
import { Form, FormValues } from "./Form"
import { SuccessMessage } from "./SuccessMessage"
import { RecaptchaValidation } from "./RecaptchaValidation"

const ERROR_NOTIFICATION_TIMEOUT = 5000

export const SubscribeDialog = () => {
  const { locale } = usePageProps<NewslettersPageProps>()
  const [subscribed, setSubscribed] = useState(false)
  const [open, setOpen] = useState(false)
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const { ids, toggle } = useSubscribedNewslettersContext()
  const { toggleSubscription, isLoading } = useToggleSubscription()
  const regionCode = useRegionCode()
  const { push } = useNotifications()

  /**
   * Open the dialog whenever there are newsletters selected or the user has subscribed (thank you message).
   */
  useEffect(() => {
    setOpen(subscribed || ids.size > 0)
  }, [ids.size, subscribed])

  const onSubmit = useCallback(
    async ({ email }: FormValues) => {
      const recaptchaToken = (await recaptchaRef.current?.executeAsync()) ?? undefined

      toggleSubscription({
        regionCode,
        email,
        recaptchaToken,
        newsletterListIds: ids,
        action: "subscribe",
      })
        .then(() => setSubscribed(true))
        .catch(({ errors }: ErrorResponseFormat) =>
          push({
            type: "error",
            title: errors[0],
            duration: ERROR_NOTIFICATION_TIMEOUT,
          }),
        )
    },
    [ids, push, regionCode, toggleSubscription],
  )

  /**
   * Allow closing whenever users have successfully subscribed.
   * When closing, we will reset the dialog state:
   * => Remove the selected newsletters from the context
   * => Set `subscribed` state to `false`
   * => Close the dialog
   */
  const onClose = useCallback(() => {
    ids.forEach((id) => toggle(id))
    setSubscribed(false)
    setOpen(false)
  }, [ids, toggle])

  return (
    <StickyDialog open={open} onClose={subscribed ? onClose : undefined}>
      <RecaptchaValidation recaptchaRef={recaptchaRef} locale={locale} />

      <div className="tc-container container flex h-28 items-center text-white sm:mx-auto sm:h-36">
        {subscribed ? <SuccessMessage /> : <Form onSubmit={onSubmit} submitting={isLoading} />}
      </div>
    </StickyDialog>
  )
}
