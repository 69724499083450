import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { newslettersPage, sessions } from "common/lib/router"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { ReactNode } from "react"

interface LinkProps {
  href: string
  children: ReactNode
}

const Link = ({ href, children }: LinkProps) => (
  <a href={href} className="text-red-600 underline">
    {children}
  </a>
)

/**
 * Component that displays a message to sign in or sign up for anonymous users in our
 * newsletters page.
 */
export const SessionPromptMessage = () => {
  const t = useTranslations("newsletters")
  const regionCode = useRegionCode()

  const returnToPath = newslettersPage.newslettersPath({ regionCode })
  const signInPath = sessions.signInPath({ returnToPath })
  const signupPath = sessions.signUpPath({ returnToPath })

  const messageBody = t("message.to_manage_your_newsletters.body")
  return (
    <div className="text-center font-medium">
      {t("message.to_manage_your_newsletters.beginning")}{" "}
      <Link href={signInPath}>{t("message.to_manage_your_newsletters.sign_in")}</Link>{" "}
      {t("message.to_manage_your_newsletters.or")}{" "}
      <Link href={signupPath}>{t("message.to_manage_your_newsletters.create_an_account")}</Link>.
    </div>
  )
}
