import clsx from "classnames"
import { ReactNode } from "react"
import { PlusCircleIcon, EyeIcon } from "@heroicons/react/24/outline"
import { CheckCircleIcon, ArrowPathIcon } from "@heroicons/react/24/solid"
import { Maybe } from "common/types/graphql"

export enum Style {
  Default = "default",
  Loading = "loading",
  Preview = "preview",
  Selected = "selected",
}

export interface ButtonProps {
  style?: Style
  /**
   * The URL that the button points to.
   * Using this prop will render the button as an anchor tag.
   * This prop will be used in priority over the `onClick` prop in case both are provided.
   */
  href?: Maybe<string>
  /**
   * The target attribute for the anchor tag. Defaults to `_blank`.
   * This prop will be ignored if the `href` prop is not provided.
   */
  target?: string
  /**
   * Whether the button should take the full width of its container on tablets and smaller devices.
   * Its text will be centered.
   */
  fullWidthTablets?: boolean
  /**
   * Whether the button should display an icon.
   */
  showIcon?: boolean
  /**
   * Additional classes used for tracking clicks on button/links.
   */
  trackingClassName?: string
  /**
   * Callback function to be called when the button is clicked.
   * Note that this prop will be ignored if the `href` prop is provided.
   */
  onClick?: () => void
  children: ReactNode
}

/**
 * Newsletters button component, which can be rendered as a button or an anchor tag.
 * Buttons in the loading state will display a loading spinner and will be disabled.
 */
export const Button = ({
  style = Style.Default,
  href,
  target = "_blank",
  fullWidthTablets = false,
  showIcon = true,
  trackingClassName,
  onClick,
  children,
}: ButtonProps) => {
  const LeadIcon =
    style === Style.Loading
      ? ArrowPathIcon
      : style === Style.Default
      ? PlusCircleIcon
      : style === Style.Selected
      ? CheckCircleIcon
      : EyeIcon

  const noCallToAction = !href && !onClick

  const classes = clsx(
    "inline-flex items-center gap-x-1 rounded-full p-2.5 font-bold transition lg:px-4 lg:py-2.5",
    {
      "w-full justify-center 2xl:w-auto": fullWidthTablets,
      "bg-black text-white": style === Style.Default,
      "bg-white outline outline-1 outline-black": style === Style.Selected,
      "bg-gray-300 text-gray-50 cursor-default pointer-events-none": style === Style.Loading,
      "bg-transparent": style === Style.Preview,
      "opacity-25 cursor-default pointer-events-none": style === Style.Preview && noCallToAction,
    },
    trackingClassName,
  )

  const isDisabled = style === Style.Loading

  const buttonContent = (
    <>
      {showIcon && (
        <LeadIcon
          aria-hidden="true"
          className={clsx("size-4 lg:size-5", { "animate-spin": style === Style.Loading })}
        />
      )}

      <span className="text-3xs leading-none lg:text-sm">{children}</span>
    </>
  )

  if (href) {
    return (
      <a href={href} target={target} className={classes} aria-disabled={isDisabled}>
        {buttonContent}
      </a>
    )
  }

  return (
    <button type="button" onClick={onClick} className={classes} disabled={isDisabled}>
      {buttonContent}
    </button>
  )
}
