import { createPortal } from "react-dom"
import { ReactNode } from "react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useTranslations } from "modules/i18n/hooks/useTranslations"

interface StickyDialogProps {
  open?: boolean
  onClose?: () => void
  noPortal?: boolean
  children?: ReactNode
}

/**
 * The sticky dialog is a closable full width banner, rendered at the bottom of the screen.
 */
export const StickyDialog = ({ open, noPortal, children, onClose }: StickyDialogProps) => {
  const t = useTranslations("components.modal")

  if (!open) {
    return null
  }

  const content = (
    <div className="fixed bottom-0 w-full bg-gray-800 text-white">
      <div className="relative flex flex-col">
        {onClose ? (
          <div className="absolute right-1.5 top-1.5 md:right-3 md:top-3">
            <button type="button" onClick={onClose}>
              <span className="sr-only">{t("close")}</span>
              <XMarkIcon aria-hidden="true" className="size-5 md:size-8" />
            </button>
          </div>
        ) : null}

        {children}
      </div>
    </div>
  )

  // The `noPortal` prop is only used for Storybook, so we can safely ignore it from the test coverage.
  /* istanbul ignore next */
  return noPortal ? content : createPortal(content, document.body)
}
