import { newslettersPage } from "common/lib/router"
import { useCsrfToken } from "common/hooks/data/useCsrfToken"
import { useCallback, useState } from "react"

export interface SubscribeEmailParams {
  regionCode: string
  email: string
  recaptchaToken?: string
  newsletterListIds: Set<string>
  action: "subscribe" | "unsubscribe"
}

const DEFAULT_LOCATION = "newsletters-subscription-page-redesign"

interface SuccessResponseFormat {
  success: boolean
}
export interface ErrorResponseFormat {
  errors: string[]
}

/**
 * Hook to toggle an email subscription to newsletters given a region code, email and newsletter list ids.
 */
export const useToggleSubscription = () => {
  const { token: CSRFToken } = useCsrfToken()
  const [isLoading, setIsLoading] = useState(false)

  const toggleSubscription = useCallback(
    async ({
      regionCode,
      email,
      recaptchaToken,
      newsletterListIds,
      action,
    }: SubscribeEmailParams) => {
      setIsLoading(true)

      const method = action === "subscribe" ? "POST" : "DELETE"
      const path = newslettersPage.subscribePath({ regionCode, format: "json" })
      const response = await fetch(path, {
        method,
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": CSRFToken,
        },
        body: JSON.stringify({
          subscribe: {
            email,
            location: DEFAULT_LOCATION,
            recaptcha_token: recaptchaToken,
            newsletter_list_ids: Array.from(newsletterListIds),
          },
        }),
      })

      setIsLoading(false)

      if (!response.ok) {
        const errorResponse: ErrorResponseFormat = await response.json()
        return Promise.reject(errorResponse)
      }

      const successResponse: SuccessResponseFormat = await response.json()
      return successResponse
    },
    [CSRFToken],
  )

  return { isLoading, toggleSubscription }
}
