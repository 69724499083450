import { useCallback } from "react"
import {
  ErrorResponseFormat,
  useToggleSubscription,
} from "modules/newsletters/hooks/useToggleSubscription"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { useNotifications } from "common/components/NotificationBar/useNotifications"
import { useSubscribedNewslettersContext } from "modules/newsletters/contexts"
import { useViewer } from "common/hooks/data/useViewer"
import { useViewerSubscriptions } from "common/hooks/data/useViewerSubscriptions"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { ButtonLabels, NewsletterProps } from "modules/newsletters/types"
import { GroupItem } from "common/types/graphql"
import { Hero } from "modules/newsletters/components/Hero"
import { NewsletterCard } from "modules/newsletters/components/NewsletterCard"

export interface NewsletterSubscriberProps {
  groupItem: GroupItem
  type: "hero" | "card"
}

/**
 * The newsletter subscriber is responsible for rendering a newsletter card and hero, making sure we
 * trigger the correct behaviour of their subscribe buttons based on user's presence or subscription status.
 *
 * => Anonymous users context: we will only toggle selected newsletters in the context.
 * => Signed in users context: we will toggle selected newsletters in the context and make a request to the backend to
 * subscribe/unsubscribe the user to the selected newsletters.
 */
export const NewsletterSubscriber = ({ groupItem, type }: NewsletterSubscriberProps) => {
  const { toggleSubscription, isLoading: loading } = useToggleSubscription()
  const regionCode = useRegionCode()
  const { ids: newsletterIds, toggle: toggleContext } = useSubscribedNewslettersContext()
  const { set: setNotifications } = useNotifications()
  const { isSignedIn } = useViewer()
  const { getPrimaryEmailAddress } = useViewerSubscriptions()
  const t = useTranslations("newsletters")

  const newsletterCardButtonLabels: ButtonLabels = isSignedIn
    ? {
        preview: t("button.preview"),
        select: t("button.select"),
        selected: t("button.subscribed"),
      }
    : {
        preview: t("button.preview"),
        select: t("button.select"),
        selected: t("button.selected"),
      }

  const handleSubscription = useCallback(() => {
    const { id } = groupItem
    const isCurrentlySubscribed = newsletterIds.has(id)

    toggleContext(id)

    if (!isSignedIn) {
      return
    }

    const email = getPrimaryEmailAddress()
    const action = isCurrentlySubscribed ? "unsubscribe" : "subscribe"
    const newsletterListIds = new Set([id])

    toggleSubscription({
      regionCode,
      email,
      action,
      newsletterListIds,
      recaptchaToken: undefined, // We don't need a reCAPTCHA token for signed-in users
    })
      .then(() => {
        setNotifications([
          {
            type: "success",
            title: t("message.your_newsletter_preferences_have_been_updated"),
          },
        ])
      })
      .catch(({ errors }: ErrorResponseFormat) => {
        setNotifications([{ type: "error", title: errors[0] }])
        toggleContext(id)
      })
  }, [
    groupItem,
    toggleContext,
    isSignedIn,
    getPrimaryEmailAddress,
    newsletterIds,
    toggleSubscription,
    regionCode,
    setNotifications,
    t,
  ])

  const commonProps: NewsletterProps = {
    ...groupItem,
    buttonLabels: newsletterCardButtonLabels,
    selected: newsletterIds.has(groupItem.id),
    onSelect: handleSubscription,
    loading,
  }

  return type === "hero" ? (
    <Hero {...commonProps} imageSrc={groupItem.heroImageUrl} />
  ) : (
    <NewsletterCard
      {...commonProps}
      description={groupItem.pitch}
      imageSrc={groupItem.thumbnailImageUrl}
    />
  )
}
