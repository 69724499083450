import { useContext, useMemo } from "react"
import { Context } from "common/components/NotificationBar/Context"
import { NotificationProps } from "common/components/NotificationBar/types"

export const useNotifications = () => {
  const { notifications, setNotifications } = useContext(Context)

  return useMemo(
    () => ({
      clear: () => setNotifications([]),
      get: () => notifications,
      push: (notification: NotificationProps) => {
        setNotifications((prevNotifications) => [...prevNotifications, notification])
      },
      set: (notifications: NotificationProps[]) => setNotifications(notifications),
    }),
    [notifications, setNotifications],
  )
}
