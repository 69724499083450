import { useTranslations } from "modules/i18n/hooks/useTranslations"

export const Headings = () => {
  const t = useTranslations("newsletters")

  return (
    <>
      <h1 className="my-4 text-center font-serif text-2xl font-bold lg:text-5xl">
        {t("heading.our_newsletters")}
      </h1>
      <h2 className="mb-4 text-center font-serif text-base">{t("heading.sub_heading")}</h2>
    </>
  )
}
