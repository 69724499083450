import type { GetServerSideProps } from "next"
import type { NextPageWithLayout } from "common/types/page"
import { Layout as NewslettersPageLayout } from "pages/newsletters/layout"
import { AppProps, getAppServerSideProps } from "common/lib/getAppServerSideProps"
import { useRememberRegion } from "common/hooks/data/useRememberRegion"
import { MetaAttributes } from "common/components/MetaAttributes"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { gql } from "common/lib/gql"
import getConfig from "next/config"
import { fetchGraphqlQuery } from "common/lib/fetchGraphqlQuery"
import { Group, NewslettersPageQuery } from "common/types/graphql"
import { tracer } from "common/lib/tracer"
import { usePageProps } from "common/hooks/data/usePageProps"
import { Groups } from "modules/newsletters/components/Groups"
import { PrivacyPolicy } from "modules/newsletters/components/PrivacyPolicy"
import { SubscribeDialog } from "modules/newsletters/components/SubscribeDialog"
import { useViewer } from "common/hooks/data/useViewer"
import { useViewerSubscriptions } from "common/hooks/data/useViewerSubscriptions"
import { useSubscribedNewslettersContext } from "modules/newsletters/contexts"
import { useEffect, useState } from "react"
import { TabbedNavigation } from "modules/newsletters/components/TabbedMenu/TabbedNavigation"
import { Headings } from "modules/newsletters/components/Headings"
import { useSearchParams } from "next/navigation"
import { SessionPromptMessage } from "modules/newsletters/components/SessionPromptMessage"
import { TabbedNavigationTransition } from "modules/newsletters/components/TabbedMenu/TabbedNavigationTransition"

export interface NewslettersPageProps extends AppProps {
  groups: Group[]
}

const QUERY = gql`
  query newslettersPage($regionCode: String!) {
    newslettersPage(regionCode: $regionCode) {
      cacheKey
      groups {
        name
        groupItems {
          id
          name
          promoted
          frequency
          description
          pitch
          previewUrl
          heroImageUrl
          thumbnailImageUrl
        }
      }
    }
  }
`

export const getServerSideProps: GetServerSideProps<NewslettersPageProps> = async ({
  query,
  res,
  req,
}) => {
  const appProps = await getAppServerSideProps({ query, res, req })
  const { serverRuntimeConfig } = getConfig()
  const { regionCode } = appProps

  let headers: { Authorization: string; User?: string } = {
    Authorization: `Bearer ${serverRuntimeConfig.TC_FRONTEND_TOKEN}`,
  }

  const { newslettersPage } = await fetchGraphqlQuery<NewslettersPageQuery>(
    QUERY,
    headers,
    { regionCode },
    tracer,
  )

  res.setHeader("Cache-Control", "no-store")
  res.setHeader("Surrogate-Control", "max-age=14400") // Newsletter sub page expires in 4 hours
  res.setHeader("Surrogate-Key", newslettersPage?.cacheKey || "")

  return {
    props: { ...appProps, groups: newslettersPage?.groups || [] },
  }
}

const NewslettersPage: NextPageWithLayout<NewslettersPageProps> = () => {
  useRememberRegion()
  const t = useTranslations("newsletters")
  const { setAllIds } = useSubscribedNewslettersContext()
  const { groups } = usePageProps<NewslettersPageProps>()
  const { isSignedIn } = useViewer()
  const { getNewsletterSubscriptions } = useViewerSubscriptions()
  const searchParams = useSearchParams()
  const [navigationFeatureFlip, setNavigationFeatureFlip] = useState(false)

  useEffect(() => {
    // Feature flip to see if we should render the tabbed navigation.
    // We will render the tabbed navigation if the user is signed in and the `?navigation=true` query string is present.
    const navigationSearchParam = searchParams.get("navigation")
    if (navigationSearchParam === "true") {
      setNavigationFeatureFlip(true)
    }

    // We don't want to change the context state if the user is not signed in, as it might cause a race condition
    // with the newsletter subscription context state, unselecting newsletters that the user has selected (before
    // fetching the `viewer`'s GraphQL data).
    if (!isSignedIn) return

    const newsletterSubscriptionIds = getNewsletterSubscriptions().map(
      (sub) => sub.newsletterListId,
    )
    setAllIds(newsletterSubscriptionIds)
  }, [isSignedIn, getNewsletterSubscriptions, setAllIds, searchParams])

  return (
    <>
      <MetaAttributes title={t("meta.title")} description={t("meta.description")} />
      <Headings />
      <TabbedNavigationTransition>
        {isSignedIn && navigationFeatureFlip ? <TabbedNavigation /> : null}
        {!isSignedIn && navigationFeatureFlip ? <SessionPromptMessage /> : null}
      </TabbedNavigationTransition>
      <Groups groups={groups} />
      <PrivacyPolicy />
      {isSignedIn ? null : <SubscribeDialog />}
    </>
  )
}

NewslettersPage.getLayout = (page) => <NewslettersPageLayout>{page}</NewslettersPageLayout>

export default NewslettersPage
