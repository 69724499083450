import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react"

interface ContextShape {
  ids: Set<string>
  setAllIds: (ids: string[]) => void
  toggle: (id: string) => void
}

/**
 * Creates a React Context with its hook which contains a list of subscribed newsletters IDs.
 * The context also provides a function to toggle a newsletter ID.
 */
const createNewsletterContext = () => {
  const Context = createContext<ContextShape>({
    ids: new Set(),
    setAllIds: () => {},
    toggle: () => {},
  })

  const Provider = ({ children }: PropsWithChildren) => {
    const [ids, setIds] = useState<Set<string>>(new Set())

    const toggle = useCallback(
      (id: string) => {
        ids.has(id) ? ids.delete(id) : ids.add(id)
        setIds(new Set(ids))
      },
      [ids],
    )

    const setAllIds = useCallback((newsletterIds: string[]) => setIds(new Set(newsletterIds)), [])

    const contextValue = useMemo(() => ({ ids, setAllIds, toggle }), [ids, setAllIds, toggle])

    return <Context.Provider value={contextValue}>{children}</Context.Provider>
  }

  const useContextValue = () => useContext(Context)

  return { Provider, useContextValue }
}

export const {
  Provider: SubscribedNewslettersContext,
  useContextValue: useSubscribedNewslettersContext,
} = createNewsletterContext()

export const {
  Provider: SelectedNewslettersContext,
  useContextValue: useSelectedNewslettersContext,
} = createNewsletterContext()
