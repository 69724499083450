import { PropsWithChildren, StrictMode } from "react"
import { Header } from "common/components/Header"
import { Content } from "pages/view/layout"
import { Footer } from "common/components/Footer"
import { SubscribedNewslettersContext } from "modules/newsletters/contexts"

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const currentYear = new Date().getFullYear()

  return (
    <StrictMode>
      <SubscribedNewslettersContext>
        <div className="flex h-screen flex-col">
          <Header noDesktopMenu />
          <Content>{children}</Content>
          <Footer year={currentYear} />
        </div>
      </SubscribedNewslettersContext>
    </StrictMode>
  )
}
