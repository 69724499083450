import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { NewspaperIcon, PencilIcon } from "@heroicons/react/24/outline"
import { usePathname } from "next/navigation"
import { TabbedMenu, TabProps } from "../TabbedMenu"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { newslettersPage } from "common/lib/router"

/**
 * Component that will render the tabbed navigation for the newsletters page, using the
 * TabbedMenu component.
 */
export const TabbedNavigation = ({}) => {
  const pathName = usePathname()
  const regionCode = useRegionCode()
  const t = useTranslations("newsletters")
  const { newslettersPath, managementPath } = newslettersPage

  const tabs: TabProps[] = [
    {
      current: pathName === newslettersPath({ regionCode }),
      href: newslettersPath({ regionCode }),
      icon: NewspaperIcon,
      name: t("label.navigation.subscribe"),
    },
    {
      current: pathName === managementPath({ regionCode }),
      href: managementPath({ regionCode }),
      icon: PencilIcon,
      name: t("label.navigation.manage_my_newsletters"),
    },
  ]

  return <TabbedMenu tabs={tabs} />
}
