import { Card } from "common/components/Card"
import { Button, Style } from "modules/newsletters/components/Button"
import { NewsletterProps } from "modules/newsletters/types"

export const NewsletterCard = ({
  name,
  frequency,
  description,
  imageSrc,
  previewUrl,
  buttonLabels,
  selected = false,
  loading,
  onSelect,
}: NewsletterProps) => {
  return (
    <Card image={{ src: imageSrc }}>
      <div
        className="font-sans-heading font-bold text-black lg:text-lg"
        data-testid="newsletter-card-name"
      >
        {name}
      </div>
      <div className="text-xs font-medium uppercase text-indigo-600 md:text-sm">{frequency}</div>
      <div className="font-serif text-2xs leading-relaxed lg:text-base">{description}</div>
      <div className="mt-auto flex w-full flex-row flex-wrap justify-between gap-2 pt-4">
        <Button
          style={loading ? Style.Loading : selected ? Style.Selected : Style.Default}
          onClick={onSelect}
          trackingClassName="newsletter-card-select-button"
          fullWidthTablets
        >
          {selected ? buttonLabels.selected : buttonLabels.select}
        </Button>
        <Button
          href={previewUrl}
          style={Style.Preview}
          trackingClassName="newsletter-card-preview-button"
          fullWidthTablets
        >
          {buttonLabels.preview}
        </Button>
      </div>
    </Card>
  )
}
