import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { useSubscribedNewslettersContext } from "modules/newsletters/contexts"
import { useState } from "react"
import { validateEmail } from "common/lib/validateEmail"
import { useDebouncedValue } from "modules/editor/hooks/useDebouncedValue"
import { ArrowPathIcon } from "@heroicons/react/24/solid"
import clsx from "classnames"
import { DisclosureNotice } from "../DisclosureNotice"

export interface FormValues {
  email: string
}

export interface FormProps {
  onSubmit: (values: FormValues) => void
  submitting?: boolean
}

export const Form = ({ onSubmit, submitting = false }: FormProps) => {
  const t = useTranslations("newsletters")
  const [email, setEmail] = useState("")
  const { ids } = useSubscribedNewslettersContext()
  const debouncedEmailValue = useDebouncedValue(email)
  const isEmailValid = validateEmail(debouncedEmailValue)
  const selectedLabel =
    ids.size === 1 ? t("label.selected_newsletters.one") : t("label.selected_newsletters.other")
  const isFormDisabled = !isEmailValid || submitting

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit({ email })
      }}
      role="form"
      className="flex flex-wrap gap-1.5 lg:mx-auto lg:w-10/12 2xl:gap-8"
    >
      <div className="w-full text-base font-semibold sm:w-3/12 sm:font-sans-heading sm:text-xl sm:font-bold">
        <span className="sm:block sm:text-4xl">{ids.size}</span> {selectedLabel}
      </div>

      <div className="flex w-7/12 flex-wrap sm:w-6/12">
        <input
          type="email"
          placeholder={t("input.email.placeholder")}
          className="h-8 grow rounded-sm p-2 text-base text-black placeholder:text-gray-500 sm:h-12 sm:p-4 sm:text-base"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <DisclosureNotice className="mt-4 hidden text-xs sm:block" />
      </div>

      <button
        disabled={isFormDisabled}
        className={clsx(
          "ml-3 inline-flex h-8 grow items-center justify-center gap-x-2 rounded-full px-1.5 text-sm font-semibold hover:bg-gray-700 hover:text-white focus:bg-black focus:text-white sm:ml-6 sm:h-12 sm:w-2/12 sm:text-base",
          { "bg-white text-black": !isFormDisabled, "bg-gray-300 text-gray-50": isFormDisabled },
        )}
      >
        {t("button.subscribe")}
        {submitting ? (
          <ArrowPathIcon aria-hidden="true" className="-mr-0.5 size-5 animate-spin" />
        ) : null}
      </button>

      <DisclosureNotice className="block text-3xs sm:hidden" />
    </form>
  )
}
