import { useTranslations } from "modules/i18n/hooks/useTranslations"

interface DisclosureNoticeProps {
  className?: string
}

export const DisclosureNotice = ({ className }: DisclosureNoticeProps) => {
  const t = useTranslations("newsletters")

  return (
    /*
      Disclosure notice copy is coming from our I18n files, which needs to support HTML tags so translators can add
      links to Google's reCAPTCHA privacy policy page. We are consciously using `dangerouslySetInnerHTML` here
      because we trust the source translations (coming from Phrase).
    */
    <div className={className} dangerouslySetInnerHTML={{ __html: t("disclosure_notice_html") }} />
  )
}
