/**
 * Extracts a number from a `next/router` query string.
 * The number will be returned as a string.
 */
export const extractNumberFromQueryString = (param: string | string[] | undefined) => {
  if (!param) {
    return undefined
  }

  const params = Array.isArray(param) ? param : [param]
  const firstParam = params[0]

  const match = firstParam.match(/\d+/)
  return match ? match[0] : undefined
}
