import { Group, GroupItem } from "common/types/graphql"
import { useRouter } from "next/router"
import { extractNumberFromQueryString } from "./utilities/query"
import { NewsletterSubscriber } from "modules/newsletters/components/NewsletterSubscriber"

interface GroupsProps {
  groups: Group[]
}

/**
 * Component that will render newsletter groups as a hero and a list of newsletter cards.
 * This component contains the logic to pluck out the promoted newsletter from the given list of groups,
 * rendering on as the hero of the first group and the rest as newsletter cards.
 */
export const Groups = ({ groups }: GroupsProps) => {
  const { query } = useRouter()

  if (!groups.length) {
    return null
  }

  /*
   * Identify the promoted newsletter.
   *
   * The promoted newsletter (hero type) is a special slot rendered if a group item is promoted.
   *
   * The query string `promoted` can override this to select the promoted newsletter.
   *
   * If no promoted newsletter exists, the hero slot will not be rendered.
   */
  const promotedIdQueryString = extractNumberFromQueryString(query.promoted)

  const groupItems = groups.flatMap((group) => group.groupItems)
  const promotedItem: GroupItem | undefined = groupItems.find((item) => item.promoted)
  const promotedNewsletter = promotedItem
    ? groupItems.find((item) => item.id === promotedIdQueryString) || promotedItem
    : undefined

  return (
    <main>
      {groups.map(({ name, groupItems }, index) => {
        const isFirstGroup = index === 0
        const regularNewsletters = groupItems.filter(
          (groupItem) => groupItem.id !== promotedNewsletter?.id,
        )

        return (
          <section key={name}>
            <h3 className="mb-3 mt-16 font-sans-heading text-lg font-bold">{name}</h3>

            {isFirstGroup && promotedNewsletter && (
              <NewsletterSubscriber type="hero" groupItem={promotedNewsletter} />
            )}

            <div className="my-5 grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-8">
              {regularNewsletters.map((groupItem) => (
                <NewsletterSubscriber key={groupItem.id} type="card" groupItem={groupItem} />
              ))}
            </div>
          </section>
        )
      })}
    </main>
  )
}
