import { useTranslations } from "modules/i18n/hooks/useTranslations"

export const SuccessMessage = () => {
  const t = useTranslations("newsletters")

  return (
    <div className="mx-auto w-9/12 text-center text-sm md:w-8/12 md:text-xl md:font-bold">
      {t("message.subscribe_success")}
    </div>
  )
}
