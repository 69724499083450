import { useEffect, useMemo, useState } from "react"
import debounce from "lodash/debounce"

const DEFAULT_WAIT_TIME = 500

/**
 * Debounces a value and returns the value. The value will delay updating the value for 500ms by default.
 */
export const useDebouncedValue = <T>(value: T, waitTime = DEFAULT_WAIT_TIME) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  const debouncedSet = useMemo(
    () =>
      debounce((value) => {
        setDebouncedValue(value)
      }, waitTime),
    [waitTime],
  )

  useEffect(() => {
    debouncedSet(value)
  }, [value, debouncedSet])

  return debouncedValue
}
