import { Locale } from "modules/i18n/config/locale"
import getConfig from "next/config"
import { RefObject } from "react"
import ReCAPTCHA from "react-google-recaptcha"

const googleRecaptchaSiteKey = (): string =>
  getConfig().publicRuntimeConfig.GOOGLE_RECAPTCHA_SITE_KEY

export const isEnvEnabled = (): boolean =>
  getConfig().publicRuntimeConfig.GOOGLE_RECAPTCHA_ENABLED == "true"

interface RecaptchaValidationProps {
  locale: Locale
  recaptchaRef?: RefObject<ReCAPTCHA>
}

export const RecaptchaValidation = ({ recaptchaRef, locale }: RecaptchaValidationProps) => {
  if (!isEnvEnabled()) {
    return null
  }

  return (
    <ReCAPTCHA ref={recaptchaRef} hl={locale} sitekey={googleRecaptchaSiteKey()} size="invisible" />
  )
}
